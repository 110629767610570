<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <v-card
          v-show="!item.disabled"
          :disabled="item.disabled"
          :color="item.color"
          :key="item"
          v-for="item of $root.menu"
          @click="$router.push(item.route)"
          dark
          style="margin-top:1em"
        >
          <div>
            <v-card-title class="headline">
              <v-icon>{{ item.icon }}</v-icon>
              <div style="margin-left:0.5em">{{ item.name }}</div>
            </v-card-title>
            <v-card-subtitle>{{ item.description }}</v-card-subtitle>
            <v-card-actions>
              <v-btn text>
                Pirmyn
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      menu: [
        {
          label: "",
        },
      ],
    };
  },
  mounted() {},
};
</script>

<style></style>
